<template>
  <div class="wiki">
    <div class="title">帮助文档</div>
    <div class="wiki_info" v-if="isShowWikiInfo">
      <router-link to="/wiki">
        <el-button class="back_list_btn" @click="backList">返回</el-button>
      </router-link>
      <div class="wiki_info_content" v-html="this.item.content"></div>
    </div>
    <div class="wiki_list" v-if="!isShowWikiInfo">
      <van-list>
        <van-cell v-for="item in tableData" :key="item.id">
          <p @click="()=>getWikiInfo(item.id)">
            <span class="list_title">{{ item.title }}</span>
            <span class="list_des">{{ item.updated_at }}</span>
          </p>
        </van-cell>
      </van-list>
      <div class="nodata" v-show="haveData">暂无数据</div>
    </div>
  </div>
</template>
<script>
import {getWikiInfoApi, getWikiListApi} from "api/Wiki";

export default {
  data() {
    return {
      loading: true,
      tableData: [
        {
          id: 0,
          title: "",
          type: 0,
          updated_at: ""
        }
      ],
      item: {
        id: 0,
        title: "",
        content: "",
        type: 0,
        updated_at: ""
      },
      type: {
        0: "未分类",
        1: "关于配置",
        2: "常见问题",
      },
      isShowWikiInfo: false,
      haveData: false
    };
  },
  mounted() {
    getWikiListApi().then(res => {
      if (res.code == 0) {
        this.loading = false;
        this.tableData = res.data;
        console.log(this.tableData)
      }
    });
  },
  methods: {
    getWikiInfo(id) {
      getWikiInfoApi({id}).then(res => {
        if (res.code === 0) {
          this.item = res.data
          this.isShowWikiInfo = true
          console.log(this.item)
        }
      });
    },
    backList() {
      this.isShowWikiInfo = false
      this.item = null
    }
  }
};
</script>
<style lang="scss" scoped>
.wiki {
  background: #fff;
  padding: 16px;
  padding-top: 80px;

  .title {
    padding-bottom: 35px;
    font-size: 30px;
    color: #517cfc;
  }

  .wiki_list {
    p {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;

      .list_title {
        font-size: 16px;
        color: #7e7e7e;
      }

      .list_des {
        font-size: 16px;
        color: #202020;
      }
    }

    .van-cell {
      margin-top: 16px;
      padding: 10px 0;
    }

    .nodata {
      color: #969799;
      font-size: 14px;
      line-height: 50px;
      text-align: center;
    }
  }

  .wiki_info {
    text-align: left;

    .back_list_btn {
      margin-bottom: 10px;
    }

    .wiki_info_content {
      margin-top: 10px;
    }
  }
}
</style>