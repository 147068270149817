import Ajax from './http';

// wiki列表
export function getWikiListApi(values) {
	return Ajax.post('/document/list', values);
}
// wiki正文
export function getWikiInfoApi(values) {
	return Ajax.post('/document/info', values);
}
